<template>
    <div class="pages">
        <pageHeader @search="search"></pageHeader>
        <div class="box">
            <div class="main">
                <acountNav></acountNav>
                <div class="acount-box">
                    <div class="acount-header">
                        <div class="acount-header-item" :class="index === headerIdx ? 'acount-header-item-active' : ''"
                            v-for="(item, index) in headerLists" :key="index
                                " @click="tapHeaderNav(index)">{{ item }}</div>
                    </div>
                    <div class="acount-main">
                        <div class="article-lists" v-if="headerIdx === 0">
                            <div class="article-item" v-for="(item, index) in collectionLists" :key="index"
                                @click="goDetails(index)">
                                <div class="title-box">
                                    <span class="column" v-for="(item, tagIndex) in item.detail.tags" :key="tagIndex">#{{
                                        item }}</span>
                                    <span class="title">{{ item.detail.title }}</span>
                                </div>
                                <span class="time">{{ item.detail.creationTime.split('T')[0] + ' ' + item.detail.creationTime.split('T')[0] + ' ' + item.detail.creationTime.split('T')[1].split('.')[0] }}</span>
                                <span class="content">{{ item.detail.description }}</span>
                                <div class="footer-box">
                                    <span class="num">阅读 {{ item.detail.cn }} · 评论 {{ item.detail.pv }}</span>
                                    <img @click.stop="cancelCollect(item.detail.id , item.type , index)" src="../../static/icon/collection.png">
                                </div>
                            </div>
                            <noData v-if="collectionLists.length === 0" title="暂无数据"></noData>
                            <getMore @getMore="getMore" :state="getMoreState"></getMore>
                        </div>
                        <div class="study-lists" v-if="headerIdx === 1">
                            <div class="study-item" v-for="(item, index) in collectionLists" :key="index"
                                @click="goDetails(index)">
                                <img :src="item.url">
                                <div class="study-item-right">
                                    <span class="title">课程标题如何学习股市选股</span>
                                    <span class="abstract">随着我国人口结构变化、技术的迭代、产业转型升级的迫切，对高技能人才的需求不断叠加，但社会</span>
                                    <span class="time">2022年2月22日</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import noData from '@/components/noData.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import acountNav from '@/components/acountNav.vue'
import getMore from '@/components/getMore.vue'
import { getCollectLists, cancelCollect } from '@/api/collect.js'
export default {
    name: "collection",
    components: {
        pageHeader,
        pageFooter,
        acountNav,
        getMore,
        noData
    },
    data() {
        return {
            headerLists: ['文章'],
            // headerLists: ['文章', '课程'],
            headerIdx: 0,
            collectionLists: [],
            listsRequestdata: {
                page: 0,
                pageSize: 10,
                filter: {
                    type: 4
                }
            },
            getMoreState: 'getMore',
            total:null
        }
    },
    created() {
        this.getCollectLists()
    },
    methods: {
        /**
         * 搜索
         */
         search(e) {
            this.title = e
            this.topTotal = 0
            this.articleLists = []
            this.page = 0
            this.getTopArticle()
        },
        /**
         * 加载更多
         */
         getMore() {
            this.getMoreState = 'loading'
            setTimeout(() => {
                this.getCollectLists()
            }, 800)
        },
        /**
         * 获取收藏列表
         */
        getCollectLists() {
            if (this.total === this.collectionLists.length) {
                this.getMoreState = 'noMore'
                return
            }
            this.listsRequestdata.page++
            getCollectLists(this.listsRequestdata).then(res => {
                this.collectionLists = this.collectionLists.concat(res.items)
                this.total = res.totalCount
                if(res.items.length < this.listsRequestdata.pageSize){
                    this.getMoreState = 'noMore'
                }else{
                    this.getMoreState = 'getMore'
                }
            })
        },
        /**
         * 取消收藏
         */
        cancelCollect(id , type , index) {
            cancelCollect({
                id:id,
                type:type
            }).then(() => {
                this.collectionLists.splice(index , 1)
                this.$message.success('取消收藏成功');
            })
        },
        /**
         * 切换nav
         */
        tapHeaderNav(index) {
            this.headerIdx = index
            this.listsRequestdata.page = 1
            this.listsRequestdata.filter.type = 2
            this.getCollectLists()
        },
        /**
         * 查看详情
         */
         goDetails(index){
            this.$router.push({ path: "/details",query:{id:this.collectionLists[index].detail.id} });
         }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.acount-box {
    width: 888px;
    background-color: #ffffff;
    min-height: calc(100vh - 120px - 16px - 28px);
    margin-top: 16px;
    margin-bottom: 28px;
}

.acount-header {
    width: 888px;
    border-bottom: 1px solid #E8E8E8;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
}

.acount-header-item {
    height: 60px;
    margin-right: 70px;
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.acount-header-item-active {
    position: relative;
    color: #DD0722;
}

.acount-header-item-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #DD0722;
}

.acount-main {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article-lists {
    width: 888px;
    background-color: #ffffff;
}

.article-item {
    width: 848px;
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px solid #CDD2D9;
    cursor: pointer;
}

.article-item:last-child {
    border: none;
}

.article-item .title-box {
    display: flex;
    align-items: center;
}

.article-item .title-box span {
    font-size: 18px;
    margin-right: 8px;
    font-weight: bold;
}

.article-item .title-box .column {
    color: #4181B8;
}

.article-item .title-box .title {
    color: #06121E;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.article-item .time {
    color: #BCBCBC;
    font-size: 12px;
    margin-top: 6px;
}

.article-item .content {
    display: block;
    margin-top: 16px;
    font-size: 16px;
    color: #06121E;
}

.article-item .footer-box {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.article-item .footer-box .num {
    font-size: 12px;
    color: #BCBCBC;
}

.article-item .footer-box img {
    width: 20px;
    height: 20px;
}

.study-lists {
    width: 888px;
    background-color: #ffffff;
}

.study-item {
    width: 848px;
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px solid #CDD2D9;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.study-item:last-child {
    border: none;
}

.study-item img {
    width: 210px;
    height: 120px;
    background-color: #8C9198;
    margin-right: 16px;
}

.study-item .study-item-right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.study-item .study-item-right .title {
    font-size: 18px;
    color: #06121E;
    font-weight: bold;
}

.study-item .study-item-right .abstract {
    color: #999999;
    margin-top: 10px;
    font-size: 14px;
}

.study-item .study-item-right .time {
    color: #999999;
    font-size: 14px;
    margin-top: 40px;
}
</style>
